import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Backoffice Bridgestone"
      src="/static/bs-logo-white.svg"
      {...props}
      width="200"
    />
  );
};

export default Logo;
